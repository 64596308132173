import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { searchEnable } from '../../features/searchEnableSlice'
import { useDispatch, useSelector } from "react-redux";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useDebouncedCallback } from 'use-debounce';
import { getCartCount } from "../../features/cartProductCountSlice";
import MiniCart from "../miniCart";
import { isAuthenticated } from "../../utils/auth";
import { handleLogout } from "../../helper/actions";
import { miniCartStatus } from "../../features/miniCartSlice";
import { Offcanvas } from "react-bootstrap";
import { Categories } from "../../features/CategoryDataSlice";
import LoadingBar from "react-top-loading-bar";
import { useGetAllCategoriesQuery, useGetCartCountApiQuery, useSearchProductMutation } from "../../features/apiSlice";
import { getSearched } from "../../features/searchProducts";
import { useMediaQuery } from "react-responsive";

const header = () => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const navigate = useNavigate();
    const [categoriesDetail, setCategories] = useState([])
    const { data: productCount } = useSelector((state) => state.cartCount);
    const products = useSelector((state) => state.searched);
    // const [products, setProducts] = useState({});
    const [searchInput, setSearchInput] = useState("");
    const [allProducts, setAllProducts] = useState([]);
    const [resetSearch, setResetSearch] = useState(false);
    const [dropdowns, setDropdowns] = useState("");
    const [count, setCount] = useState()
    const cartStatus = useSelector((state) => state.miniCartEnabled);
    const location = useLocation();
    const onlyLogo = location.pathname === "/checkout/cart";
    const hideheader = location.pathname.includes("/sharecart");
    const search = useSelector((state) => state.searchEnable);
    const { pageLoader } = useSelector((state) => state.FullPageLoader);
    const { data: loadCategories } = useSelector((state) => state.categories);
    const [open, setOpen] = useState(false);
    const {data: getCartCountApi, refetch} = useGetCartCountApiQuery();
    const {data: getAllCat} = useGetAllCategoriesQuery();
    const [searchProduct] = useSearchProductMutation();
    const [searchLoader, setSearchLoader] = useState(false);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 540px)' });
    
    const productsToShow = isSmallScreen ? 2 : 3;

    useEffect(() => {
        if (isLoading) {
            ref?.current?.continuousStart();
            const timer = setTimeout(() => setIsLoading(false), 2000); // Simulating a 2-second load time
            return () => clearTimeout(timer);

        } else {
            ref?.current?.complete();
        }
    }, [isLoading]);

    useEffect(() => {

        const init = async () => {
            AOS.init();
         
        }
        init()
    }, []);

    useEffect(() => {
        if(getAllCat && loadCategories?.length == 0){
            dispatch(Categories(getAllCat[0].children_data))
            setCategories(getAllCat[0].children_data)
        }else{
            setCategories(loadCategories)
        }
    }, [getAllCat, loadCategories])


    const handleSearch = () => {
        dispatch(searchEnable(!search.searchEnable));
    };

    const handleLogoutClick = async () => {
        handleLogout();
    };


    useEffect(() => {
        const checkAuth =  () => {
            refetch(); //  cart count Api
        }
        checkAuth()
    }, [isAuthenticated()])

    useEffect(()=>{
        if(getCartCountApi){
            const response = getCartCountApi;
            dispatch(getCartCount({ cart: {}, count: response[0]?.count ? response[0]?.count : 0 }))
            setCount(response[0]?.count ? response[0]?.count : 0)
        }
    },[getCartCountApi])

    useEffect(() => {
        setCount(productCount?.count)
    }, [productCount])

    useEffect(() => {
        cartStatus.enable || dropdowns == "enabled" || open ? dispatch(searchEnable(false)) : ""
    }, [cartStatus.enable, dropdowns, open]);

    useEffect(() => {
        setIsLoading(true)
        cartStatus.enable ? dispatch(miniCartStatus(false)) : "";
    }, [location.pathname]);

    const debounced =
        useDebouncedCallback(
             (eve) => {
                const action = async() => {
                    setResetSearch(false);
                    try {
                        setSearchLoader(true);
                        const response = await searchProduct({ search: eve.target.value, limit: 12, page_no: 1 }).unwrap();
                        setSearchLoader(false);
                        if(response && response[0]){
                            dispatch(getSearched({info: response[0], pageNo: 1, value: eve.target.value, restart: true}));
                        }
            
                    } catch (err) {
                        setSearchLoader(false);
                        // console.error('Failed to Search Product: ', err);
                    }
                }
                
               action()
            },
            1000
        );

    useEffect(() => { 
        // console.log("products",products),
        products?.data?.products?.length > 0 ? setAllProducts(products?.data?.products) : setAllProducts([]) 
    }, [products])

    useEffect(() => {
        if (search.searchEnable) {
            setResetSearch(true)
            setSearchInput("")
            dispatch(miniCartStatus(false))
            setDropdowns("hidden")
        } 
    }, [search.searchEnable])

    

    const handleLogoClick = () => {
        window.location.href = '/';
    };

    const handleLinkClick = () => {
        setOpen(false);
    };



    return (<>
       
        {pageLoader ? <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready" id="mgs-ajax-loading">
            <div className="mfp-bg mfp-ready"></div>
            <div className="preloader"></div>
        </div> : ""}
        
        {!hideheader ?
            <>
            
            
                <nav className={`navbar navbar-expand-lg navbar-light head-back ${location.pathname=="/" && dropdowns == "enabled" ? "show-brown-border" : "show-transparent-border"}`} >
                    <div className="container">
                        <Link className="navbar-brand mobile-logo" role="button" onClick={handleLogoClick}><img src={`${process.env.REACT_APP_AmazonLink}/header/logo.webp`} alt="logo" title="logo" height={46} width={163} /></Link>

                        <div className="head-items">
                            <div className="mobile-show-links">
                                <ul className="navbar-nav m-0-auto mb-2 mb-lg-0" id="right-menu">
                                    <li className="nav-item " id="show" >
                                        <Link className="nav-link sarch mob-hides" title="Search" onClick={() => handleSearch()} id="navbarDropdown" role="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">

                                            <svg className="mob-search" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="4
                                        \7=
                                        evenodd" d="M7.78691 0.0626831C12.0805 0.0626831 15.573 3.55516 15.573 7.84879C15.573 9.87451 14.7956 11.7221 13.5235 13.1087L16.0267 15.6067C16.261 15.8409 16.2618 16.2199 16.0275 16.4542C15.9107 16.5725 15.7564 16.6309 15.6029 16.6309C15.4502 16.6309 15.2967 16.5725 15.1791 16.4558L12.6457 13.9294C11.313 14.9967 9.62324 15.6357 7.78691 15.6357C3.49327 15.6357 0 12.1424 0 7.84879C0 3.55516 3.49327 0.0626831 7.78691 0.0626831ZM7.78691 1.26202C4.15451 1.26202 1.19934 4.21639 1.19934 7.84879C1.19934 11.4812 4.15451 14.4364 7.78691 14.4364C11.4185 14.4364 14.3737 11.4812 14.3737 7.84879C14.3737 4.21639 11.4185 1.26202 7.78691 1.26202Z" fill="white" />
                                            </svg>


                                        </Link>
                                    </li>
                                    <li className="nav-item"  >
                                        <Link className="nav-link product-dropdown sup mob-profile" onClick={() => setDropdowns("enabled")}>

                                            <svg onClick={() => setDropdowns("enabled")} className="mob-search" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M14.0102 13.7545C14.0102 16.5414 10.1885 16.8635 7.31456 16.8635L7.1089 16.8634C5.27787 16.8589 0.617188 16.7433 0.617188 13.7376C0.617188 11.0077 4.28536 10.6428 7.13741 10.6291L7.52022 10.6288C9.35112 10.6333 14.0102 10.7489 14.0102 13.7545ZM7.31456 11.8969C3.7118 11.8969 1.88547 12.5159 1.88547 13.7376C1.88547 14.9704 3.7118 15.5952 7.31456 15.5952C10.9165 15.5952 12.742 14.9763 12.742 13.7545C12.742 12.5218 10.9165 11.8969 7.31456 11.8969ZM7.31456 0.0626831C9.79025 0.0626831 11.8034 2.07672 11.8034 4.5524C11.8034 7.02809 9.79025 9.04128 7.31456 9.04128H7.28751C4.81689 9.03367 2.81554 7.01879 2.82397 4.54987C2.82397 2.07672 4.83803 0.0626831 7.31456 0.0626831ZM7.31456 1.27009C5.5043 1.27009 4.03139 2.74214 4.03139 4.5524C4.02548 6.35674 5.48739 7.82795 7.29004 7.83472L7.31456 8.43842V7.83472C9.12398 7.83472 10.596 6.36182 10.596 4.5524C10.596 2.74214 9.12398 1.27009 7.31456 1.27009Z" fill="white" />
                                            </svg>


                                        </Link>
                                        <ul className={`submenu drop-down account ${dropdowns} `}>


                                        <li className="nav-item support-list-parts" onClick={() => setDropdowns("hidden")}>
                                                    <Link className="dropdown-item" to="/customer/account">
                                                        <div className="support-list-left">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/myaccount-toplink.png`} alt="support" title="support" height={27} width={29} loading="lazy" />
                                                        </div>
                                                        <div className="support-txt">
                                                            <p>My Account</p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            {localStorage.getItem("customerToken") ? <>

                                              
                                                <li className="nav-item support-list-parts" onClick={() => setDropdowns("hidden")}>
                                                    <Link className="dropdown-item" to="/wishlist">
                                                        <div className="support-list-left">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/wishlist-toplink.png`} alt="support" title="support" height={27} width={30} loading="lazy" />
                                                        </div>
                                                        <div className="support-txt">
                                                            <p>My Wishlist </p>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="nav-item support-list-parts" onClick={() => setDropdowns("hidden")}>
                                                    <Link className="dropdown-item" to="/catalog/product-compare">
                                                        <div className="support-list-left">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/compare.webp`} alt="support" title="support" height={27} width={30} loading="lazy" />
                                                        </div>
                                                        <div className="support-txt">
                                                            <p>Compare Products </p>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="nav-item support-list-parts" onClick={handleLogoutClick} >
                                                    <Link className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                        <div className="support-list-left">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/signin-toplink.png`} alt="support" title="support" height={27} width={32} loading="lazy" />
                                                        </div>
                                                        <div className="support-txt">
                                                            <p>Sign Out</p>
                                                        </div>
                                                    </Link>
                                                </li></> :
                                                <>
                                                    <li className="nav-item support-list-parts" onClick={() => { setDropdowns("hidden") }}>
                                                        <Link className="dropdown-item" to="/customer/account/create">
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/createacc-toplink.png`} alt="support" title="support" height={31} width={31} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p> Create an Account </p>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item support-list-parts" onClick={() => setDropdowns("hidden")}>
                                                        <Link className="dropdown-item" to="/catalog/product-compare">
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/compare.webp`} alt="support" title="support" height={27} width={30} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p>Compare Products </p>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item support-list-parts" onClick={() => setDropdowns("hidden")}>
                                                        <Link className="dropdown-item" to="/customer/account/login">
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/signin-toplink.png`} alt="support" title="support" height={31} width={31} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p>Sign In</p>
                                                            </div>
                                                        </Link>
                                                    </li></>}


                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link cart-mini-mobile position-relative d-flex align-items-center gap-2" onClick={() => dispatch(miniCartStatus(true))} title="My Cart">
                                            {/* <div>My Cart</div> */}
                                            <img src="/images/cart-mob.webp" className="cart-in" alt="cart" title="cart" width="18" height="18" />

                                            {count >= 0 ?
                                                <div className="cart-value position-absolute">
                                                    <p className="d-flex justify-content-center align-items-center" style={{ background: `${count > 0 ? "#c0974f" : "#001e42"}` }}>{count}</p>
                                                </div> : ""}
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <button className="navbar-toggler" type="button" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation" onClick={() => setOpen(true)} style={{ opacity: open ? "0" : "1" }}>
                                <span className="navbar-toggler-icon"></span>
                            </button>


                        </div>

                        <div className={`navbar-collapse collapse not-for-mobile `} >
                            <ul className={`navbar-nav ms-0 mb-2 mb-lg-0 ${onlyLogo ? "d-none" : ""}`} id="left-menu">
                                <li className="nav-item" onMouseEnter={() => setDropdowns("enabled")} onMouseLeave={() => setDropdowns("hidden")}>
                                    <Link className="product-dropdown nav-link active ps-0" aria-current="page"
                                    >Products <svg className="ico-cent" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.11338 1.02327C1.25081 0.87906 1.46586 0.865951 1.6174 0.983939L1.66082 1.02327L5 4.52702L8.33918 1.02327C8.47661 0.87906 8.69166 0.865951 8.84321 0.983939L8.88662 1.02327C9.02405 1.16748 9.03654 1.39314 8.9241 1.55216L8.88662 1.59772L5.27372 5.38888C5.13629 5.53309 4.92124 5.5462 4.7697 5.42821L4.72628 5.38888L1.11338 1.59772C0.962207 1.43909 0.962207 1.1819 1.11338 1.02327Z" fill="#000" stroke="#000" strokeWidth="0.5" />
                                        </svg></Link>

                                 
                                    <div className={`submenu drop-down w-100 ${dropdowns}`} id="dropdown">

                                    <div className="large-menu">
                                    <div className="container">
                                            <div className="row gx-0">
                                                <div className="col-lg-2 col-md-12">
                                                    <div className="left-large-menu-side">
                                                        {/* <div className="main-top-title">
                                                            <h2>Products</h2>
                                                        </div>  */}
                                                        <div className="products-main-list">
                                                            <ul className="pros-data">
                                                                <li className="dropdown-item pros-data-list"> <Link onClick={() => setDropdowns("hidden")} to={`${process.env.REACT_APP_RANGE_PAGE}`} title="Ranges" >Ranges</Link></li>
                                                                <li className="dropdown-item pros-data-list"><Link onClick={() => setDropdowns("hidden")} to="/refrigerators"  title="Refrigerators">Refrigerators</Link></li>
                                                                <li className="dropdown-item pros-data-list"><Link onClick={() => setDropdowns("hidden")} to="/dishwashers"  title="Dishwashers">Dishwashers</Link></li>
                                                                <li className="dropdown-item pros-data-list"><Link onClick={() => setDropdowns("hidden")} to="/accessories"  title="Accessories">Accessories</Link></li>
                                                                <li className="dropdown-item pros-data-list"><Link onClick={() => setDropdowns("hidden")} to="/ventilation-hoods"  title="Ventilation">Ventilation</Link></li>
                                                               
                                                                <li className="dropdown-item pros-data-list"><Link onClick={() => setDropdowns("hidden")} to="/packages"  title="Packages">Packages</Link></li>
                                                                <li className="dropdown-item pros-data-list"><Link onClick={() => setDropdowns("hidden")} to="/in-stock"  title="In-Stock">In-Stock</Link></li>
                                                                <li className="dropdown-item pros-data-list"><Link onClick={() => setDropdowns("hidden")} to="/comingSoon"  title="Configurator">Configurator</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-10 col-md-12">
                                                    <div className="row gx-0 right-large-menu-side">
                                                        <div className="col-lg-3 col-md-3" role="button" onClick={()=>{setDropdowns("hidden"),navigate(`${process.env.REACT_APP_RANGE_PAGE}`)}}>
                                                            <div className="picture-one">
                                                                <div className="pic-onee overflow-hidden">
                                                                    <img src={`${process.env.REACT_APP_AmazonLink}/hallmanusa_cms/pic-1.webp`} alt="Range Series images" title="Range Series" width="264" height="320" loading="lazy" />
                                                                </div>
                                                                <div className="pic-onee-txt">
                                                                    <p> <Link to={`${process.env.REACT_APP_RANGE_PAGE}`} className="range-txt" title="link"> Ranges</Link>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3" role="button" onClick={()=>{setDropdowns("hidden"),navigate(`/refrigerators`)}}>
                                                            <div className="picture-one">
                                                                <div className="pic-onee overflow-hidden">
                                                                    <img src={`${process.env.REACT_APP_AmazonLink}/hallmanusa_cms/pic-2.webp`} alt="Range Series images" title="Range Series" width="264" height="320" loading="lazy" />
                                                                </div>
                                                                <div className="pic-onee-txt">

                                                                    <p><Link to="/refrigerators" className="range-txt" title="link">Refrigerators</Link>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3" role="button" onClick={()=>{setDropdowns("hidden"),navigate(`/dishwashers`)}}>
                                                            <div className="picture-one">
                                                                <div className="pic-onee overflow-hidden">
                                                                    <img src={`${process.env.REACT_APP_AmazonLink}/hallmanusa_cms/pic-3.webp`} alt="Range Series images" title="Range Series" width="264" height="320" loading="lazy" />
                                                                </div>
                                                                <div className="pic-onee-txt">
                                                                    <p><Link to="/dishwashers" className="range-txt" title="link">Dishwasher</Link>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-3" role="button" onClick={()=>{setDropdowns("hidden"),navigate(`/ventilation-hoods`)}}>
                                                            <div className="picture-one">
                                                                <div className="pic-onee overflow-hidden">
                                                                    <img src={`${process.env.REACT_APP_AmazonLink}/hallmanusa_cms/pic-4.webp`} alt="Range Series images" title="Range Series" width="264" height="320" loading="lazy" />
                                                                </div>
                                                                <div className="pic-onee-txt">
                                                                    <p><Link to="/ventilation-hoods"className="range-txt" title="link">ventilation</Link>
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                         
                                                </div>
                                            </div>
                             
                                    </div>
                                  </div>
                                </div>


                                </li>
                                <li className="nav-item position-relative" onMouseEnter={() => setDropdowns("enabled")} onMouseLeave={() => setDropdowns("hidden")}>
                                    <Link className="product-dropdown nav-link differ" aria-current="page" to="#"
                                        id="slidetoggleAbout">Support <svg className="ico-cent" width="10" height="6"
                                            viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.11338 1.02327C1.25081 0.87906 1.46586 0.865951 1.6174 0.983939L1.66082 1.02327L5 4.52702L8.33918 1.02327C8.47661 0.87906 8.69166 0.865951 8.84321 0.983939L8.88662 1.02327C9.02405 1.16748 9.03654 1.39314 8.9241 1.55216L8.88662 1.59772L5.27372 5.38888C5.13629 5.53309 4.92124 5.5462 4.7697 5.42821L4.72628 5.38888L1.11338 1.59772C0.962207 1.43909 0.962207 1.1819 1.11338 1.02327Z"
                                                fill="#fff" stroke="#fff" strokeWidth="0.5" />
                                        </svg>
                                    </Link>

                                    <ul className={` drop-down w-100 support-area ${dropdowns} `} id="dropDowns">
                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link to="/faq/index/" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/faq.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>FAQ</p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link to="/services-and-parts" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/service_parts.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>Services & Parts </p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link to="/warranty" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/warranty.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>Warranty </p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link to="/contact-us" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/contact_us.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>Contact Us</p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link to="/explainer/index/IlveRangeVideos" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/our_team.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>Customer Training videos</p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link to="/financing" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/financeicon.png`} alt="support" title="support" height={65} width={67} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>Finance</p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item position-relative" onMouseEnter={() => setDropdowns("enabled")} onMouseLeave={() => setDropdowns("hidden")}>


                                    <Link className="product-dropdown nav-link  differ" aria-current="page" to="#"
                                        id="slidetoggleAbout">Gallery <svg className="ico-cent" width="10" height="6"
                                            viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.11338 1.02327C1.25081 0.87906 1.46586 0.865951 1.6174 0.983939L1.66082 1.02327L5 4.52702L8.33918 1.02327C8.47661 0.87906 8.69166 0.865951 8.84321 0.983939L8.88662 1.02327C9.02405 1.16748 9.03654 1.39314 8.9241 1.55216L8.88662 1.59772L5.27372 5.38888C5.13629 5.53309 4.92124 5.5462 4.7697 5.42821L4.72628 5.38888L1.11338 1.59772C0.962207 1.43909 0.962207 1.1819 1.11338 1.02327Z"
                                                fill="#fff" stroke="#fff" strokeWidth="0.5" />
                                        </svg>
                                    </Link>

                                    <ul className={` drop-down w-100 support-area ${dropdowns} `} id="dropdowns">
                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link onClick={() => setDropdowns("hidden")} to="/imageGallery/" className="dropdown-item">
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/imagegalleryicon.png`} alt="support" title=" support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p> Image Gallery</p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                        <li className="nav-item" >
                                            <div className="support-list-parts">
                                                <Link onClick={() => setDropdowns("hidden")} to="/explainer/video/index" className="dropdown-item">
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/videogallewryicon.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p> Video Gallery </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item position-relative" onMouseEnter={() => setDropdowns("enabled")} onMouseLeave={() => setDropdowns("hidden")}>

                                    <Link className="product-dropdown nav-link  differ" aria-current="page" to="#"
                                        id="slidetoggleAbout">About Us <svg className="ico-cent" width="10" height="6"
                                            viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.11338 1.02327C1.25081 0.87906 1.46586 0.865951 1.6174 0.983939L1.66082 1.02327L5 4.52702L8.33918 1.02327C8.47661 0.87906 8.69166 0.865951 8.84321 0.983939L8.88662 1.02327C9.02405 1.16748 9.03654 1.39314 8.9241 1.55216L8.88662 1.59772L5.27372 5.38888C5.13629 5.53309 4.92124 5.5462 4.7697 5.42821L4.72628 5.38888L1.11338 1.59772C0.962207 1.43909 0.962207 1.1819 1.11338 1.02327Z"
                                                fill="#fff" stroke="#fff" strokeWidth="0.5" />
                                        </svg>
                                    </Link>
                                    <ul className={` drop-down w-100 support-area ${dropdowns} `} id="dropdowns">
                                        <li className="nav-item">
                                            <div className="support-list-parts">
                                                <Link to="/about-us" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/about-us.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>About Us</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="support-list-parts">
                                                <Link to="/contact-us" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/contact_us.png`} alt="contact us" title="contact us" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>Contact Us</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="support-list-parts">
                                                <Link to="/our-story" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/our_story.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p> Our story</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="support-list-parts">
                                                <Link to="/our-team" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/our_team.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p> Our team </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div className="support-list-parts ">
                                                <Link to="/blog" className="dropdown-item" onClick={() => setDropdowns("hidden")}>
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/blog_icon_top_link.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p> Blog </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="navbar-nav " id="logo-desktop">
                                <li>
                                    <Link className="navbar-brand" role="button" onClick={handleLogoClick}><img src={`${process.env.REACT_APP_AmazonLink}/header/logo.webp`} alt="logo" title="logo" height={46} width={163} />
                                        <p className="estb" >Est. 2005</p>
                                    </Link>
                                </li>
                            </ul>
                            <ul className={`navbar-nav m-0-auto mb-2 mb-lg-0 ${onlyLogo ? "d-none" : ""} `} id="right-menu">
                                <li className="nav-item" >
                                    <Link className="nav-link" to="#" title="Search" onClick={() => handleSearch()}>Search</Link>
                                </li>
                                <li className="nav-item position-relative" onMouseEnter={() => setDropdowns("enabled")} onMouseLeave={() => setDropdowns("hidden")}>
                                    <Link className="product-dropdown nav-link " aria-current="page" to="#"
                                        id="slidetoggleAccount">Account <svg className="ico-cent" width="10" height="6"
                                            viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1.11338 1.02327C1.25081 0.87906 1.46586 0.865951 1.6174 0.983939L1.66082 1.02327L5 4.52702L8.33918 1.02327C8.47661 0.87906 8.69166 0.865951 8.84321 0.983939L8.88662 1.02327C9.02405 1.16748 9.03654 1.39314 8.9241 1.55216L8.88662 1.59772L5.27372 5.38888C5.13629 5.53309 4.92124 5.5462 4.7697 5.42821L4.72628 5.38888L1.11338 1.59772C0.962207 1.43909 0.962207 1.1819 1.11338 1.02327Z"
                                                fill="#fff" stroke="#fff" strokeWidth="0.5" />
                                        </svg></Link>
                                    <ul className={` drop-down support-area w-100 account  ${dropdowns} `} id="dropdowns">

                                        <li className="nav-item">

                                            <div className="support-list-parts">
                                                <Link onClick={() => setDropdowns("hidden")} to="/customer/account" className="dropdown-item" >
                                                    <div className="support-list-left">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/myaccount-toplink.png`} alt="support" title="support" height={27} width={29} loading="lazy" />
                                                    </div>
                                                    <div className="support-txt">
                                                        <p>My Account</p>
                                                    </div>
                                                </Link>
                                            </div>

                                        </li>
                                        {localStorage.getItem("customerToken") ?
                                            <>
                                               
                                                <li className="nav-item">

                                                    <div className="support-list-parts">
                                                        <Link onClick={() => setDropdowns("hidden")} to="/wishlist" className="dropdown-item" >
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/wishlist-toplink.png`} alt="support" title="support" height={27} width={30} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p>My Wishlist </p>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                </li>
                                                <li className="nav-item support-list-parts" onClick={() => setDropdowns("hidden")}>
                                                    <Link className="dropdown-item" to="/catalog/product-compare">
                                                        <div className="support-list-left">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/compare.webp`} alt="support" title="support" height={27} width={30} loading="lazy" />
                                                        </div>
                                                        <div className="support-txt">
                                                            <p>Compare Products </p>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="nav-item" onClick={handleLogoutClick}>

                                                    <div className="support-list-parts">
                                                        <Link className="dropdown-item" >
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/signin-toplink.png`} alt="support" title="support" height={31} width={32} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p>Sign Out</p>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                </li>
                                            </>
                                            :
                                            <>
                                                <li className="nav-item">

                                                    <div className="support-list-parts">
                                                        <Link onClick={() => setDropdowns("hidden")} to="/customer/account/create" className="dropdown-item">
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/createacc-toplink.png`} alt="support" title="support" height={31} width={32} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p> Create an Account </p>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                </li>
                                                <li className="nav-item support-list-parts" onClick={() => setDropdowns("hidden")}>
                                                        <Link className="dropdown-item" to="/catalog/product-compare">
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/compare.webp`} alt="support" title="support" height={27} width={30} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p>Compare Products </p>
                                                            </div>
                                                        </Link>
                                                </li>
                                                <li className="nav-item">

                                                    <div className="support-list-parts">
                                                        <Link onClick={() => setDropdowns("hidden")} to="/customer/account/login" className="dropdown-item">
                                                            <div className="support-list-left">
                                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/signin-toplink.png`} alt="support" title="support" height={31} width={31} loading="lazy" />
                                                            </div>
                                                            <div className="support-txt">
                                                                <p>Sign In</p>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                </li></>}
                                    </ul>
                                </li>

                                <li className="nav-item" >
                                    <Link className="nav-link d-flex align-items-center gap-2" onClick={() => dispatch(miniCartStatus(true))} title="My Cart" ><div>My Cart</div>
                                        {count >= 0 ?
                                            <div className="cart-value ">
                                                <p className="d-flex justify-content-center align-items-center" style={{ background: `${count > 0 ? "#c0974f" : "#001e42"}` }}>{count}</p>
                                            </div>
                                            : ""}


                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    {dropdowns != "enabled"  ? <div className={`${location.pathname == "/" && !isLoading ? "show-brown-border-home" : ""} ${dropdowns}`}></div> : ""}
                
                </nav>
                <div className={`top-search-bar ${search?.searchEnable ? "d-block" : "d-none"}`}>
                    <div className="bar-area">
                        <div className="search-bar-top">
                            <div className="search-left">
                                <input type="text" placeholder="Search" value={searchInput}
                                    onChange={(e) => {   (e.target.value.trim()) ? (debounced(e), setSearchInput(e.target.value)) : (setResetSearch(true), setSearchInput(e.target.value)) }}
                                />
                            </div>
                            <div className="search-close-btn" >
                                <button type="button" className="seach-close" id="hide" onClick={() => {setSearchInput(""),dispatch(searchEnable(false))}}>

                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M12.2056 0.98546C13.1089 0.082195 14.4814 1.45447 13.5779 2.35774L8.77455 7.16113L13.5779 11.9645C14.4812 12.8678 13.1089 14.2401 12.2056 13.3368L7.40229 8.53341L2.59893 13.3368C1.69568 14.2401 0.323411 12.8678 1.22667 11.9645L6.03002 7.16113L1.22667 2.35774C0.323411 1.45447 1.69568 0.082195 2.59893 0.98546L7.40229 5.78885L12.2056 0.98546Z" fill="#AEAEAE" />
                                    </svg>

                                </button>
                            </div>
                        </div>

                    </div>

                    {!searchLoader && !resetSearch ? 
                        <div className="seach-contents">
                            <div className="search-item-top w-100">

                                <div className="product-result-list">
                                    {allProducts && products?.searchValue && allProducts?.length > 0 ?
                                        <>
                                            <div className="product-result">
                                                <p>Products ({products?.data?.product_count}) Result</p>
                                            </div>
                                            {allProducts?.slice(0, productsToShow)?.map((prod, ind) => {
                                                return <div className="pros-parts" role="button" onClick={() => { dispatch(searchEnable(false)), navigate(`/${prod?.sku?.toLowerCase()}`) }} key={ind}>
                                                    <div className="pros-parts-pic">

                                                        <img src={prod?.image} alt="Image" title="Image" width={71} height={71} />

                                                    </div>
                                                    <div className="pros-parts-txt">

                                                        <p className="sku fw-bold">{`${prod?.name} (SKU ${prod.sku})`}</p>
                                                        <p>${prod?.price}</p>

                                                    </div>
                                                </div>

                                            })}   <div className="view-all-products">
                                                <Link onClick={() => dispatch(searchEnable(false))} className="fw-bold"
                                                    to={`/catalogsearch/result/${searchInput}`} title="search">View
                                                    all results</Link>
                                            </div>
                                        </>
                                        : searchInput && allProducts?.length == 0 ?
                                            (<div className="product-result py-2">
                                                <p>Not found</p>
                                            </div>)
                                            : ""
                                    } </div>



                            </div>
                        </div> : !resetSearch ?
                        <div className="seach-contents">
                            <div className="search-item-top w-100">

                                <div className="product-result-list"><div className="product-result py-2">
                                    <p>Loading...</p>
                                </div></div>



                            </div>
                        </div> : ""}


                </div>
                <MiniCart cartData={productCount} open={cartStatus.enable} setOpen={(val) => dispatch(miniCartStatus(val))} />

                {dropdowns == "hidden" || !dropdowns ? <LoadingBar color="#A18B64" ref={ref} containerClassName='custom-loader' /> : ""} 

            </> : <div className="font-Gotham ms-2">Redirecting on cart...</div>}

        {/* header-offcanvas-start */}



        <Offcanvas show={open} tabindex="-1" placement="end" className="header-off" onHide={() => setOpen(false)}>
            <Offcanvas.Header >


                <button type="button" onClick={() => { setOpen(false) }} className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <div className="menu-accor">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingOnee">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOnee" aria-expanded="true" aria-controls="panelsStayOpen-collapseOnee">
                                    Products
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOnee" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOnee">
                                <div className="accordion-body">
                                    <div className="prod-outer">
                                        {categoriesDetail?.length > 0 && categoriesDetail?.filter((i) => { return i.id == "8" || i.id == "3" || i.id == "15" || i.id == "17" || i.id == "7" })?.map((iy, ind) => {
                                            return <Link onClick={handleLinkClick}
                                                to={iy?.id == 3 ? `${process.env.REACT_APP_RANGE_PAGE}` : `/${iy?.url_key}`} className="picture-one"
                                                key={ind}
                                            >
                                                <div className="pic-onee overflow-hidden">
                                                    <img src={`${process.env.REACT_APP_AmazonLink}${iy?.thumbnail}`} alt={`${iy?.name} image`} title={iy?.name} width={320} height={410} />
                                                </div>
                                                <div className="pic-onee-txt">
                                                    <p><Link to={iy?.id == 3 ? `${process.env.REACT_APP_RANGE_PAGE}` : `/${iy?.url_key}`}>{iy?.name}</Link></p>
                                                </div>
                                            </Link>
                                        })?.sort((a, b) => a.position - b.position)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => { handleLinkClick(), navigate("/packages") }} className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingTwoo">
                                {/* <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwoo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwoo">
     
                                    </button> */}
                                Packages
                            </h2>
                            <div id="panelsStayOpen-collapseTwoo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwoo">
                                <div className="accordion-body">

                                </div>
                            </div>
                        </div>
                        <div onClick={() => { handleLinkClick(), navigate("/in-stock") }} className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThreee">
                                {/* <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThreee" aria-expanded="false" aria-controls="panelsStayOpen-collapseThreee">
                                     </button> */}
                                In-Stock
                            </h2>
                            <div id="panelsStayOpen-collapseThreee" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThreee">
                                <div className="accordion-body">

                                </div>
                            </div>
                        </div>
                        <div onClick={() => { handleLinkClick(), navigate("/comingSoon") }} className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingFourr">
                                {/* <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFourr" aria-expanded="false" aria-controls="panelsStayOpen-collapseFourr">
                                     </button> */}
                                Configurator
                            </h2>
                            <div id="panelsStayOpen-collapseFourr" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFourr">
                                <div className="accordion-body">

                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingFivee">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFivee" aria-expanded="false" aria-controls="panelsStayOpen-collapseFivee">
                                    Support
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseFivee" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFivee">
                                <div className="accordion-body">
                                    <div className="menu-outer">
                                        <Link onClick={handleLinkClick} to="/faq/index/" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/faq.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p>FAQ</p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick}
                                            to="/comingSoon" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/service_parts.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p>Services & Parts </p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick} to="/warranty" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/warranty.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p>Warranty </p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick} to="/contact-us" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/contact_us.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p>Contact Us</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingSixx">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSixx" aria-expanded="false" aria-controls="panelsStayOpen-collapseSixx">
                                    Gallery
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseSixx" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSixx">
                                <div className="accordion-body">

                                    <div className="menu-outer">
                                        <Link onClick={handleLinkClick} to="/imageGallery" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/imagegalleryicon.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                            </div>
                                            <div className="support-txt">
                                                <p> Image Gallery</p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick} to="/explainer/video/index" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/videogallewryicon.png`} alt="support" title="support" height={65} width={66} loading="lazy" />
                                            </div>
                                            <div className="support-txt">
                                                <p> Video Gallery </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingSevenn">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSevenn" aria-expanded="false" aria-controls="panelsStayOpen-collapseSevenn">
                                    About Us
                                </button>
                            </h2>
                            <div id="panelsStayOpen-collapseSevenn" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSevenn">
                                <div className="accordion-body">
                                    <div className="menu-outer">
                                        <Link onClick={handleLinkClick} to="/about-us" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/about-us.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p> About Us</p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick} to="/contact-us" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/contact_us.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p>Contact Us</p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick} to="/our-story" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/our_story.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p> Our story</p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick} to="/our-team" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/our_team.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p> Our team </p>
                                            </div>
                                        </Link>
                                        <Link onClick={handleLinkClick} to="/blog" className="nav-item d-flex align-items-center gap-2">
                                            <div className="support-list-left">
                                                <img src={`${process.env.REACT_APP_AmazonLink}/header/blog_icon_top_link.png`} alt="support" title="support" height={65} width={66} />
                                            </div>
                                            <div className="support-txt">
                                                <p> Blog </p>
                                            </div>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </Offcanvas.Body>
        </Offcanvas>

         
        

    </>)
}

export default header




